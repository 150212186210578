[anim=ripple] {
  position: relative;
  overflow: hidden;
}

[anim=ripple]:before {
  content: "";
  position: absolute;
  display: block;
  background: var(--ripple-background, white);
  border-radius: 50%;
  pointer-events: none;
  top: calc(var(--y) * 1px);
  left: calc(var(--x) * 1px);
  width: calc(var(--d) * 1px);
  height: calc(var(--d) * 1px);
  opacity: calc(var(--o, 1) * var(--ripple-opacity, 0.3));
  transition: calc(var(--t, 0) * var(--ripple-duration, 600ms)) var(--ripple-easing, linear);
  transform: translate(-50%, -50%) scale(var(--s, 1));
  transform-origin: center;
}


.mat-button {
  --ripple-background: white;
  --ripple-opacity: 0.3;
  --ripple-duration: 600ms;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--primary);
  color: var(--white);
  border: 0;
  font-size: 0.9rem;
  padding: 7px 20px;
  font-weight: 400;
  border-radius: 4px;
  height: 35px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.Button_group {
  float: right;
}

.MuiSvgIcon-root {
  cursor: pointer;
}