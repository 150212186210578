.card.card-tale {
  border-radius: 7px;
  background: #ffffff !important;
  color: #019688;
  border: none;
  box-shadow: 4px 4px 11px 0px #c3c3c3;
}
.top-card {
  text-align: center;
  border-radius: 7px;
  color: #019688;
  border: none;
  background: #019688;
  box-shadow: 6px 6px 20px 1px #e9e9e9;
}
