.dropdown-content {
  display: none;
  position: absolute;
  background-color: #dbd9d9;
  min-width: 160px;
  left: -100px;
  top: 15px;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
  display: block;
  z-index: 1;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
