.card-configuration{
    background: white;
    padding: 15px;
    margin: 3% 5%;
    border-radius: 1em;
    box-shadow: 0px 0px 0.7px -6px rgba(0, 0, 0, 0),
    0px 0px 1.7px -6px rgba(0, 0, 0, -0.003),
    0px 0px 3.3px -6px rgba(0, 0, 0, -0.006),
    0px 0px 5.8px -6px rgba(0, 0, 0, -0.002),
    0px 0px 10.9px -6px rgba(0, 0, 0, 0.028),
    0px 0px 26px -6px rgba(0, 0, 0, 0.11);

}


.card-configuration_content{
    padding: 5px 12px;
}

.card-configuration_header{
    margin-bottom: 15px;
    margin-top: 25px;
}
.card-configuration_header h3{
    margin-left: 12px;
    font-size: 1.2rem;
}
.card-configuration_header::after{
    content: '';
    display: block;
    margin-top: 15px;
    border-bottom: 1px solid #eee
}

.configimage{
    width: 100%;
}
.heading_title{
    padding: 25px;
    margin-top: 15px;
}
.heading_title h3{
    font-size: 1.5rem;
}
.heading_title p{
    color: rgb(131, 131, 131);
}