.RfqbudgetTable {
  background-color: #ffffff;
  padding: 6px 20px;
  margin-top: 36px;
  box-shadow: 0 4px 16px 0 rgb(169 184 200 / 15%);
  border-radius: 8px;
  position: relative;
}
.searchBarRfq {
  flex: none !important;
  /* height: inherit !important; */
  font-style: normal !important;
  outline: none !important;
  width: 27% !important;
  border-radius: 26px !important;
  padding: 7px 16px !important;
  border: 1px solid #ddd !important;
  background: white !important;
}
.budgetitembutton {
  font-size: 0.9rem;
  font-weight: 900;
  margin-bottom: 0px;
  background-image: linear-gradient(#ffffff, white, #ffffff);
  border-radius: 100px;
  text-align: center;
  text-decoration: none;
  padding: 10px 25px;
  color: rgb(109 109 109);
  box-shadow: 0 4px 16px 0 rgb(169 184 200 / 15%);
  border-radius: 30px;
}
.budgetitembutton:hover {
  font-size: 0.9rem;
  background: #f9f9f9;
  color: #565656;
}
