.invoicebutton {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0px;
  background-image: linear-gradient(#ffffff, white, #ffffff);
  border-radius: 100px;
  text-align: center;
  text-decoration: none;
  padding: 5px 15px;
  color: rgb(109 109 109);
  box-shadow: 0 4px 16px 0 rgb(169 184 200 / 15%);
  border-radius: 30px;
}
.invoicebutton:hover {
  font-size: 0.8rem;
  background: #f9f9f9;
  color: #565656;
}

.invoicebuttonCustom {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0px;
  background-image: linear-gradient(rgb(160, 240, 60), #A0F03C, #A0F03C);
  border-radius: 100px;
  text-align: center;
  text-decoration: none;
  padding: 5px 15px;
  color: rgb(255, 255, 255);
  box-shadow: 0 4px 16px 0 rgb(169 184 200 / 15%);
  border-radius: 30px;
}
