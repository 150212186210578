@import "rsuite/dist/rsuite.css";
.container {
  padding: 2rem 0rem;
}

h4 {
  margin: 2rem 0rem 1rem;
}

.table-image td,
th {
  vertical-align: middle;
}
