.navbarMainContainer {
  background-color: #ffffff;
  padding: 0;
  box-shadow: 0px 0.2px 0.5px -24px rgba(0, 0, 0, 0.023),
    0px 0.5px 1px -24px rgba(0, 0, 0, 0.043),
    0px 0.9px 1.7px -24px rgba(0, 0, 0, 0.059),
    0px 1.3px 2.7px -24px rgba(0, 0, 0, 0.07),
    0px 2px 4px -24px rgba(0, 0, 0, 0.076),
    0px 2.9px 5.8px -24px rgba(0, 0, 0, 0.073),
    0px 4.3px 8.7px -24px rgba(0, 0, 0, 0.064),
    0px 7.2px 13.9px -24px rgba(0, 0, 0, 0.117),
    0px 15px 26px -24px rgba(0, 0, 0, 0.31);

  z-index: 2;
}

.navbarContainer {
  background: #ffffff;
  padding: 0.5rem 2em;
  z-index: 2;
  /* border-bottom: 1px solid rgba(173, 173, 173, 0.2); */
}
.light {
  color: var(--textGrey);
  position: absolute;
  left: 20px;
  cursor: pointer;
}
.light:hover {
  color: #019688;
  font-size: 15px;
  transition: 0.4s;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .navbarContainer {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

.custom-navbar-brand {
  margin-bottom: 0;
  color: var(--primary);
}

.nav-link {
  color: var(--textGrey);
  cursor: pointer;
}
.nav-link:hover {
  color: var(--primary);
  cursor: pointer;
}
.nav-link:active {
  color: var(--primary);
  cursor: pointer;
}

.nav-link.active {
  color: var(--textGrey);
  font-weight: 700;
}

/* .notification{
    display: flex;
    justify-content: center;
    background-color: var(--primary);
}
.notificationtext{
    color: aliceblue;
} */

/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper::before{
   content: '';
    position: absolute;
    left: 42%;
    top: 0px;
    width: 0;
    height: 0;
    border-left: 20px solid #ea0f0f00;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #080808;
    clear: both;
    top: 75px;
} */

.global-search-input {
  flex: 1;
  flex-grow: 1;
  border: 0 !important;
  outline: none;
  margin-left: 1em;
}
.global-search-input:focus {
  border: 0 !important;
  outline: none;
}
.css-5duvv3-control {
  /* width: 50%; 
  border-radius: 22px; */
}
.css-2b097c-container .selectDropdown {
  width: 50% !important;
  margin-left: auto;
}

.serchSelect {
  width: 30%;
  margin-left: auto;
  margin-bottom: 20px;
}
.avatar-class {
  position: relative;
}
.avatar-class .menu-basic {
  position: absolute;
}
