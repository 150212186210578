@mixin object-center {
  display: flex;
  justify-content: center;
  align-items: center;
}


.profilebgdiv{
    width: 100%;
    height: 200px;
}
.profilebgimage{
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: top;
}


.profiledatadiv{
    margin: 79px 20px 0px 29px;
    width: 100%;
}

.profilediv1{
display: flex;
    z-index: 20;
    margin-top: -60px;
    margin-left: 200px;
}

.buttondiv{
    margin-top: 79px;
    margin-left: 386px;
}

.profiledataname{
    font-weight: bold;
}
.profileimg{

    color: transparent;
  transition: all .3s ease;
  position: relative;
  transition: all .3s ease;
      display: flex;
    justify-content: center;
    align-items: center;
} 

.changepiclabel{
    cursor: pointer;
    height: 200px;
    width: 200px;
    border-radius: 100%;
        display: flex;
    flex-direction: column;
}
.changepiclabel:hover{
      background-color: rgba(0,0,0,.6);
      color:white;
      transition: background-color .2s ease-in-out;
      border-radius: 100%;
      margin-bottom: 0;
     display: flex;
    justify-content: center;
    align-items: center;

}
.clientdata{
  display: flex;
}

@media only screen and (max-width: 600px) {
  .clientdata  {
  flex-direction: column-reverse;
    align-items: center;
  }
}


@media only screen and (max-width: 600px) {
  .profilediv1 {
   margin-left: 0px;
    align-items: center;
   flex-direction: column;
    margin-top: -113px;
  }
}


@media only screen and (max-width: 600px) {
  .profiledatadiv {
   margin-top: 0px;
    margin-left: 0px;
    padding: 0px 22px 0px 22px;
    display: flex;
    flex-direction: column;
    
  }
}

