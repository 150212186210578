.RfiTable {
  background-color: #ffffff;
  padding: 6px 20px;
  margin-top: 36px;
  box-shadow: 0 4px 16px 0 rgb(169 184 200 / 15%);
  border-radius: 8px;
  position: relative;
}
.RfisearchBar {
  flex: none !important;
  /* height: inherit !important; */
  font-style: normal !important;
  outline: none !important;
  width: 27% !important;
  border-radius: 26px !important;
  padding: 7px 16px !important;
  border: 1px solid #ddd !important;
  background: white !important;
}
