.budgetitembutton {
  font-size: 0.9rem;
  font-weight: 900;
  margin-bottom: 0px;
  background-image: linear-gradient(#ffffff, white, #ffffff);
  border-radius: 100px;
  text-align: center;
  text-decoration: none;
  padding: 10px 25px;
  color: rgb(109 109 109);
  box-shadow: 0 4px 16px 0 rgb(169 184 200 / 15%);
  border-radius: 30px;
}
.budgetitembutton:hover {
  font-size: 0.9rem;
  background: #f9f9f9;
  color: #565656;
}
.budgetTable {
  background-color: #ffffff;
  padding: 6px 20px;
  margin-top: 36px;
  box-shadow: 0 4px 16px 0 rgb(169 184 200 / 15%);
  border-radius: 8px;
  position: relative;
}
.searchBar {
  flex: none !important;
  height: 40px !important;
  font-style: normal !important;
  outline: none !important;
  width: 27% !important;
  border-radius: 26px !important;
  padding: 7px 16px !important;
  border: 1px solid #ddd !important;
  background: white !important;
  /* margin-top: -78px !important; */
}
.searchBarBudget {
  flex: none !important;
  height: 40px !important;
  font-style: normal !important;
  outline: none !important;
  width: 27% !important;
  border-radius: 26px !important;
  padding: 7px 16px !important;
  border: 1px solid #ddd !important;
  background: white !important;
  margin-top: -78px !important;
}
.actionData {
  margin-top: 10px;
  display: flex!;
  justify-content: flex-end;
  margin-right: 1rem;
  /* position: absolute !important;
  right: 46px;
  top: 5px !important;
  z-index: 0; */
}

/* .basic-multi-select {
  margin-top: 16px !important;
} */
.uploadBtn {
  border: 1px solid #cccccc;
  padding: 3px 2px;
  margin: 0px 9px;
  border-radius: 5px;
  height: 40px;
}
.uploadBtn:hover {
  background: #f5f6f7;
}
.uploadBtn:hover svg {
  transition: 0.7s;
  fill: #019688;
}
.tablebudget {
  border: 1px solid #cccccc;
  padding: 2px 0px;
  margin: 0px 9px;
  border-radius: 5px;
}
.tablebudget:hover {
  background: #f5f6f7;
}
.tablebudget:hover svg {
  transition: 0.7s;
  fill: #019688;
}
.statusTable {
  border: 1px solid #cccccc;
  padding: 2px 0px;
  margin: 0px 9px;
  border-radius: 5px;
}
.statusTable:hover {
  background: #f5f6f7;
}
.statusTable:hover svg {
  transition: 0.7s;
  fill: #019688;
}
.chartTable {
  border: 1px solid #cccccc;
  padding: 2px 0px;
  margin: 0px 9px;
  border-radius: 5px;
}
.chartTable:hover {
  background: #f5f6f7;
}
.chartTable:hover svg {
  transition: 0.7s;
  fill: #019688;
}
#budgetTableCustom > div > div > div > label {
  display: none;
}
.d-flex.actionData > div > div > div > div {
  height: 40px !important;
}

.downloadTable {
  border: 1px solid #cccccc;
  padding: 9px 0px;
  margin: 0px 9px;
  border-radius: 5px;
}
.downloadTable:hover {
  background: #f5f6f7;
}
.downloadTable:hover svg {
  transition: 0.7s;
  fill: #019688;
}
.quotationButton {
  font-size: 0.9rem;
  font-weight: 900;
  margin-bottom: 0px;
  background-image: linear-gradient(#ffffff, white, #ffffff);
  border-radius: 100px;
  text-align: center;
  text-decoration: none;
  padding: 10px 10px;
  color: rgb(109 109 109);
  box-shadow: 0 4px 16px 0 rgb(169 184 200 / 15%);
  border-radius: 30px;
}
.quotationButton:hover {
  font-size: 0.9rem;
  background: #f9f9f9;
  color: #565656;
}
