.modalbackground {
  width: 100vw;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.096);
  position: fixed;
  display: flex;
  z-index: 5;
  justify-content: flex-end;
  align-items: center;
  margin-top: -25px;
  margin-left: -15px;
}

.modalcontainer {
  width: 430px;
  height: 500px;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0px 0px 0.7px -6px rgba(0, 0, 0, 0),
    0px 0px 1.7px -6px rgba(0, 0, 0, -0.003),
    0px 0px 3.3px -6px rgba(0, 0, 0, -0.006),
    0px 0px 5.8px -6px rgba(0, 0, 0, -0.002),
    0px 0px 10.9px -6px rgba(0, 0, 0, 0.028),
    0px 0px 26px -6px rgba(0, 0, 0, 0.11);
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.closeicondiv {
  display: flex;
  justify-content: space-between;
}

.notimenu {
  display: flex;
  flex-direction: row;
  padding: 6px 0;
  border-bottom: 1.5px solid #ebe5e3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.massagelabel {
  margin-right: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-height: 3.6em;
  line-height: 1.8em;
}

.notimenu:hover {
  cursor: pointer;
  background-color: #f3efee;
  opacity: 0.9;
}

.message {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.lastseen {
  font-size: 0.8rem;
  color: grey;
}

.avatardiv {
  margin-left: 0.5rem;
  margin-right: 0.9rem;
  justify-content: center;
  align-self: center;
  border: 3px solid var(--primary);
  border-radius: 21px;
}

.onlinebutton {
  height: 10px;
  width: 10px;
  border-radius: 100px;
  background-color: green;
  justify-content: center;
  align-self: center;
}

.seeactivitydiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 22px;
}

.seeactivitydiv:hover {
  color: var(--primary);
}

.messagediv {
  overflow-y: scroll;
}

@media only screen and (max-width: 600px) {
  .modalbackground {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .modalcontainer {
    margin-top: -120px;
  }
}
