.projectSection {
  background: #ffffff;
  padding: 20px 10px;
  border-radius: 7px;
  box-shadow: 3px 3px 8px 0px #c9c9c9;
}
.projectSection .customcarddiv {
  background-color: #f7f8f9;
  width: 100%;
  border-radius: 5px;
  box-shadow: none;
  padding: 1.2em 1.5em;
  transition: 0.4s all;
  margin-bottom: 20px;
  margin-left: 0;
}
.projectSection button#noanim-tab-example-tab-home {
  /* border: 1px solid; */
  border-radius: 4px;
  background: #f7f8f9;
  border-top-right-radius: unset;
  border: none;
  margin-right: 9px;
}
.projectSection button#noanim-tab-example-tab-contact {
  border-radius: 4px;
  background: #f7f8f9;
  border-top-right-radius: unset;
  border: none;
  margin-right: 9px;
}
.projectSection button#noanim-tab-example-tab-profile {
  border-radius: 4px;
  background: #f7f8f9;
  border-top-right-radius: unset;
  border: none;
  margin-right: 11px;
}
.projectSection .nav-link.active {
  background: #019688 !important;
  color: #fff !important;
  border-radius: 4px !important;
}
.projectSection .nav-tabs {
  border-bottom: 1px solid #dee2e6;
  padding: 0 0 7px 0px;
}
