.div1 {
  /* width: 99%;
  height: 350px; */
  width: 100%;
  height: 100vh;
  overflow: scroll;
  position: relative;
  left: 0%;
}
.div1 > table {
  border-spacing: 0;
  width: 100%;
}
.div1 th {
  border-left: none;
  padding: 4px;
  width: 200px;
  height: 40px;
  min-width: 100px;
  position: sticky;
  top: 0;
  background-color: #019688;
  color: #e0e0e0;
  font-weight: normal;
  border: 1px solid white;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  z-index: 1;
}

.col_space_rowe td {
  position: sticky;
  top: 40px;
  text-align: center;
}
.div1 td {
  border-left: none;
  padding: 5px;
  width: 80px;
  min-width: 80px;
  border: 1px solid #d7dbdd;
  z-index: 1;
}

.div1 th:nth-child(1),
.div1 td:nth-child(1) {

  position: sticky;
  left: 0;
  width: 140px;
  min-width: 200px;
  border: 1px solid #d7dbdd;
}

.div1 th:nth-child(2),
.div1 td:nth-child(2) {
  position: sticky;
  left: 0;
  width: 100px;
  min-width: 100px;
  border: 1px solid #d7dbdd;
}

.div1 th:nth-child(3),
.div1 td:nth-child(3) {
  position: sticky;
  left: 0;
  width: 100px;
  min-width: 100px;
}

.div1 th:nth-child(4),
.div1 td:nth-child(4) {
  position: sticky;
  left: 0;
  width: 100px;
  min-width: 100px;
}

.div1 th:nth-child(5),
.div1 td:nth-child(5) {
  position: sticky;
  left: 0;
  width: 100px;
  min-width: 100px;
}

.div1 th:nth-child(6),
.div1 td:nth-child(6) {
  position: sticky;
  left: 0;
  width: 100px;
  min-width: 100px;
}

.div1 td:nth-child(7) {
  left: 550px;
  width: 100px;
  min-width: 100px;
}

.div1 td:nth-child(1),
.div1 td:nth-child(2),
.div1 td:nth-child(3),
.div1 td:nth-child(4),
.div1 td:nth-child(5),
.div1 td:nth-child(6),
.div1 td:nth-child(7) {
  background: white;
  background-color: white;
}
/* .div1 td:nth-child(1) {
  background-color: green;
  z-index: 4 !important;
} */
.col_space_rowe td:nth-child(1) {
  position: sticky;
  left: 0;
  width: 100px;
  min-width: 100px;
  background-color: #019688;
  color: white;
  font-size: 10px;
  text-align: center;
}
.col_space_rowe td:nth-child(2) {
  position: sticky;
  left: 0;
  width: 100px;
  min-width: 100px;
  background-color: #019688;
  color: white;
  font-size: 10px;
  text-align: center;
}
.col_space_rowe td:nth-child(3),
.col_space_rowe td:nth-child(4),
.col_space_rowe td:nth-child(5),
.col_space_rowe td:nth-child(6),
.col_space_rowe td:nth-child(7),
.col_space_rowe td:nth-child(8),
.col_space_rowe td:nth-child(9),
.col_space_rowe td:nth-child(10),
.col_space_rowe td:nth-child(11),
.col_space_rowe td:nth-child(12),
.col_space_rowe td:nth-child(13) {
  background-color: #019688;
  color: white;
  font-size: 10px;
  text-align: center;
  left: 0;
}
.col_space_rowe td {
  background-color: #019688;
  color: white;
  font-size: 10px;
  text-align: center;
}

.div1 th:nth-child(1),
.div1 th:nth-child(2),
.div1 th:nth-child(3),
.div1 th:nth-child(4),
.div1 th:nth-child(5),
.div1 th:nth-child(6),
.col_space_rowe td:nth-child(1) {
  z-index: 99;
}
.div1 th:nth-child(1) {
  text-align: left;
}
.download-table-xls-button {
  margin-top: 10px;
  margin-left: 10px;
  padding: 10px;
  background-color: #019688;
  color: white;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid white;
  border-radius: 5px;
}
.tbl_input {
  z-index: auto;
}
.div1 th:nth-child(1),
.div1 td:nth-child(1) {
  position: sticky;
  left: 0;
  width: 140px;
  min-width: 200px;
  border: 1px solid #d7dbdd;
  z-index: auto;
}
.cost_card {
  height: 200px;
  width: 100%;
  background-color: blue;
}
.target_th {
  background-color: "green";
}
.headerTable {
  position: fixed;
  z-index: 999 !important;
}

.iconClass {
  z-index: 99 !important;
}
