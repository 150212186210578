.DocumentbudgetTable {
  background-color: #ffffff;
  padding: 6px 20px;
  margin-top: 25px;
  box-shadow: 0 4px 16px 0 rgb(169 184 200 / 15%);
  border-radius: 8px;
  position: relative;
}
.searchBarDocument {
  flex: none !important;
  height: inherit !important;
  font-style: normal !important;
  outline: none !important;
  width: 27% !important;
  border-radius: 26px !important;
  padding: 13px 16px !important;
  border: 1px solid #f2f2f2 !important;
  background: white !important;
}
