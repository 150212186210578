.multiEmail {
  font-family: "sans-serif";
  width: "500px";
  border: "1px solid #eee";
  background: "#f3f3f3";
  padding: "25px";
  margin: "20px";
}
#select-style > div {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

#select-style > div > label {
  margin-bottom: 5px !important;
}

#select-style > div > div {
  margin-top: 5px !important;
}
#select-style-date > div {
  margin-top: 21px !important;
  margin-bottom: 7px !important;
}

#select-style-date > div > label {
  margin-bottom: 5px !important;
}

#select-style-date > div > input {
  margin-top: 5px !important;
}
.rs-input {
  padding: 3px 11px !important;
}
